.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  transform: translate(20px, 0px);
}

.react-datepicker__month-text--today {
    font-weight: unset;
}

.react-datepicker__month-text--keyboard-selected {
    border-radius: unset;
    background-color: unset;
    color: unset;
}

.react-datepicker__month-text--keyboard-selected[aria-selected="true"] {
    border-radius: 0.3rem;
    background-color: #2579ba;
    color: #fff;
}

@media (max-width: 576px) {
  .react-datepicker-popper {
    width: 100%;
  }

  .react-datepicker {
    width: 100%;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__month-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .react-datepicker__month-text {
    width: unset!important;
    padding: 8px;
    font-size: 16px;
  }
}
