@font-face {
  font-family: 'FontAwesome5Pro';
  src: local('FontAwesome5Pro-Regular'),
    url('./fonts/FontAwesome5Pro/FontAwesome5ProRegular.woff2') format('woff2'),
    url('./fonts/FontAwesome5Pro/FontAwesome5ProRegular.woff') format('woff'),
    url('./fonts/FontAwesome5Pro/FontAwesome5ProRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Rawline';
  src: local('Rawline-Regular'),
    url('./fonts/Rawline/RawlineRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Rawline';
  src: local('Rawline-Bold'),
    url('./fonts/Rawline/RawlineBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

.drawer-content-wrapper {
  background: transparent !important;
}

html {
  scroll-behavior: smooth;
}

@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.arrow-loading {
    opacity: 0.5;
}

.arrow-loading svg[data-icon="arrow-rotate-right"] {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.vjs-big-play-button {
    position: absolute;
    top: 0!important;
    right: 0!important;
    bottom: 0!important;
    left: 0!important;
    margin: auto;
}
